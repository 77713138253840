<script setup lang="ts">

import {ServiceStatusEnum} from "~/libs/core/types/user.roles";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
})
const {t} = useI18n({
  useScope: 'global'
})
const emit = defineEmits(['refresh', 'read', 'click'])

const getStatusLabel = (statusId: number) => {
  switch (statusId) {
    case ServiceStatusEnum.REJECTED:
      return t('notifications.bell.service.status.rejected')
    case ServiceStatusEnum.PENDING:
      return t('notifications.bell.service.status.pending')
    case ServiceStatusEnum.ACCEPTED:
      return t('notifications.bell.service.status.accepted')
    default:
      return t('common.unknown')
  }
}

const getStatusColor = (statusId: number) => {
  switch (statusId) {
    case ServiceStatusEnum.REJECTED:
      return 'red'
    case ServiceStatusEnum.PENDING:
      return 'yellow'
    case ServiceStatusEnum.ACCEPTED:
      return 'green'
    default:
      return ;
  }
}

const getServiceName = (serviceType: string) => {
  switch (serviceType) {
    case "DAMAGE":
      return t('notifications.bell.service.name.DAMAGE')
    case 'AMOUNT_CARRYOVER':
      return t('notifications.bell.service.name.AMOUNT_CARRYOVER')
    case 'STORE_IN_STOCK':
      return t('notifications.bell.service.name.STORE_IN_STOCK')
    case 'DIVIDE_INTO_PARTS':
      return t('notifications.bell.service.name.DIVIDE_INTO_PARTS')
    default:
      return t('common.unknown')
  }
}

</script>

<template>
  <AppDropdownItem
    :class="{unread: !props.item.isRead}"
    class="notification"
    @click="emit('click', props.item)"
    @mouseenter="emit('read', props.item)"
  >
    <template v-if="props.item?.type === 'requestedService'">
      <div class="requested-service">
        <div class="vin">
          {{ props.item?.command?.ctx?.vin }}
        </div>
        <div class="status">
          {{ getServiceName(props.item?.command?.ctx?.serviceType) }} -
          <span :class="[getStatusColor(props.item?.command?.ctx?.statusId)]">
            {{ getStatusLabel(props.item?.command?.ctx?.statusId) }}
          </span>
        </div>
      </div>
    </template>
    <template v-else>
      {{ props.item.title }}
    </template>
  </AppDropdownItem>
</template>


<style lang="scss" scoped>

.requested-service{
  .vin {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: #1F163B;

    margin-bottom: 15px;
  }

  .status {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;

    color: #1F163B;

    .red {
      color: #F0494F;
    }
    .yellow {
      color: #F0B94F;
    }
    .green {
      color: #4FF049;
    }
  }
}
.notification {
  //padding: 10px 28px;
  padding-top: 10px;
  padding-left: 28px;
  padding-right: 0px;
  position: relative;

  &.unread {
    &:before {
      content: "";
      position: absolute;
      top: 13px;
      left: 9px;
      width: 8px;
      height: 8px;
      background: #F0494F;
      border-radius: 50%;
    }
  }
}
</style>
