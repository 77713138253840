<script setup lang="ts">
const __MACROS_props = withDefaults(defineProps<Props>(), {
  logoTo: "/",
  menuItems: [],
  menuItemsMobile: [],
})
import { toRefs as __MACROS_toRefs } from "vue";
const {t} = useI18n({
  useScope: 'global'
})



interface Props {
  logoTo?: string;
  logoPath?: string;
  menuItems?: object[];
  menuItemsMobile?: object[];
}

const {logoPath, menuItems, menuItemsMobile, logoTo} = __MACROS_toRefs(__MACROS_props);

const router = useRouter()
const isMobileMenuOpened = ref(false)

let isLocked = ref()
const openMobileMenu = () => {
  isLocked = useScrollLock(document.body);
  isMobileMenuOpened.value = true;
  isLocked.value = true
};
const closeMobileMenu = () => {
  isLocked.value = false
  isMobileMenuOpened.value = false;
  isLocked = ref()
};

router.afterEach(() => {
  closeMobileMenu()
})

</script>

<template>
  <header class="header">
    <ThemeContainer>
      <div class="header__inner">
        <AppLogo
          :path="logoPath"
          :to="logoTo"
        />
        <ThemeHeaderMobileMenu
          :menu-items="menuItemsMobile"
          :class="{open: isMobileMenuOpened}"
          class="mobile-menu"
          @close="closeMobileMenu"
        >
          <slot name="mobile-right" />
        </ThemeHeaderMobileMenu>
        <button
          class="burger"
          @click="openMobileMenu"
        >
          <img src="/img/icons/burger.svg">
        </button>
        <ThemeHeaderMenu :menu-items="menuItems" />
        <div class="header__wrapper">
          <slot name="right" />
        </div>
      </div>
    </ThemeContainer>
  </header>
</template>

<style lang="scss" scoped>
.header {
  margin-bottom: 50px;
  background-color: #fff;
  box-shadow: 0px 4px 20px 0px rgba(31, 22, 59, 0.07);

  @media only screen and (max-width: $tablet) {
    margin-bottom: 30px;
  }


  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    height: 70px;

    @media only screen and (max-width: $tablet) {
      height: 50px;
    }

    .burger {
      display: none;

      @media only screen and (max-width: $tablet) {
        display: block;
        flex-shrink: 0;
      }
    }


    .mobile-menu {
      opacity: 0;
      visibility: hidden;
      transform: translateX(-100%);
      transition: opacity 0.3s,
      visibility 0.3s, transform .3s;

      &.open {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
      }
    }


  }

  &__wrapper {
    display: flex;
    align-items: center;
    @media only screen and (max-width: $tablet) {
      display: none;
    }
  }
}

</style>
