<script lang="ts" setup>

</script>

<template>
  <div class="container">
    <slot />
  </div>
</template>


<style lang="scss" scoped>
.container {
  max-width: 1232px;
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;
}
</style>
