<script lang="ts" setup>
const __MACROS_props = withDefaults(defineProps<MenuProps>(), {
  menuItems: []
})
import { toRefs as __MACROS_toRefs } from "vue";


const {t} = useI18n({
  useScope: "global",
});

interface MenuProps {
  menuItems?: object[];
}

const {menuItems} = __MACROS_toRefs(__MACROS_props);
const emit = defineEmits(['close'])

</script>

<template>
  <div class="mobile-menu">
    <div class="mobile-menu__head">
      <AppLogo class="mobile-menu-logo" />
      <button
        class="close mobile-menu-close"
        @click="emit('close')"
      >
        <img
          src="/img/icons/icon-close-dark.svg"
          alt="close"
        >
      </button>
    </div>
    <div class="mobile-menu__body">
      <div class="menu-header">
        <slot />
      </div>
      <nav class="menu">
        <div
          v-for="item in menuItems"
          :key="item.label"
          class="menu__item"
        >
          <div
            v-if="item.label"
            class="menu__item-label"
          >
            <NuxtLink
              :key="item?.label"
              :to="item?.to"
              class="item"
              v-bind="item.itemProps"
            >
              {{ item?.label }}
            </NuxtLink>
          </div>
          <div
            v-if="item.children"
            class="menu__item-children"
          >
            <NuxtLink
              v-for="childrenItem in item.children"
              :key="childrenItem?.label"
              :to="childrenItem?.to"
              class="item"
              v-bind="childrenItem.itemProps"
            >
              <span>{{ childrenItem?.label }}</span>
            </NuxtLink>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.menu-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  z-index: 3;
}

.mobile-menu {
  display: none;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  overflow: auto;



  @media only screen and (max-width: $tablet) {
    display: block;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
    padding: 20px 15px;

    .menu {
      display: flex;
      flex-direction: column;
      gap: 10px;

      width: 100%;

      &__item {
        &-label {
          display: flex;
          gap: 5px;
          margin-bottom: 5px;

          font-weight: 600;
          font-size: 18px;
          line-height: 18px;

          align-items: center;
          border-radius: 6px;
          color: var(--text-color-1);
          display: flex;
          font-size: 14px;
          margin-bottom: 5px;
          padding: 0 10px;
          width: -moz-fit-content;
          width: fit-content;

          .arrow {
            transition: transform .3s;
            transform: rotate(-90deg);
          }
        }

        &-children {
          display: flex;
          flex-direction: column;

          .item {
            position: relative;

            line-height: 16px;

            align-items: center;
            border-radius: 6px;
            color: var(--text-color-1);
            display: flex;
            font-size: 14px;
            margin-bottom: 5px;
            padding: 5px 10px;
            margin-left: 15px;
            width: -moz-fit-content;
            width: fit-content;

            &:before {
              content: "";
              height: 100%;
              width: 3px;
              background: linear-gradient(58.17deg, #2d2d86 0.79%, #f0494f 100%);
              position: absolute;
              right: 0;
              top: 0;
              opacity: 0;
              visibility: hidden;
              transition: opacity 0.3s, visibility 0.3s;
            }

            &.router-link-active {
              background-color: rgba(45, 45, 134, 0.05);

              &:before {
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }
    }

  }
}
</style>
