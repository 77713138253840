<script setup lang="ts">
const {data, refresh} = await useBellNotifications()
const {t} = useI18n({
  useScope: 'global'
})
const localePath = useLocalePath()

const onOpenNotification = async (toggle) => {
  refresh()
  await toggle()
}

const onReadNotification = async (item) => {
  if (item.isRead) return;
  const client = useClient()
  const result = await client.notification.readItem.mutate({
    id: item.id,
  });
  refresh()
}
const onClickNotification = async (item) => {
  if (!item.command) return;

  const command = item.command
  if (command.navigateTo){
    navigateTo(localePath(command.navigateTo))
  }
}

const countUnreadNotifications = computed(()=>{
  if (!data.value) return 0
  return data.value.filter(item => !item.isRead).length
})
</script>
<template>
  <div class="notifications">
    <AppDropdown
      width="210px"
      :items="data"
    >
      <template #trigger="{toggle}">
        <div
          class="notification-button"
          @click="onOpenNotification(toggle)"
        >
          <svg
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.99931 0C6.44619 0 5.99932 0.502734 5.99932 1.125V1.8C3.7181 2.32031 1.99938 4.59141 1.99938 7.3125V7.97344C1.99938 9.62578 1.45876 11.2219 0.48377 12.4594L0.252524 12.7512C-0.00997304 13.0816 -0.0724722 13.5563 0.0869007 13.9605C0.246274 14.3648 0.605644 14.625 0.999389 14.625H12.9992C13.393 14.625 13.7492 14.3648 13.9117 13.9605C14.0742 13.5563 14.0086 13.0816 13.7461 12.7512L13.5149 12.4594C12.5399 11.2219 11.9992 9.6293 11.9992 7.97344V7.3125C11.9992 4.59141 10.2805 2.32031 7.9993 1.8V1.125C7.9993 0.502734 7.55243 0 6.99931 0ZM8.41492 17.3426C8.78991 16.9207 8.99928 16.3477 8.99928 15.75H6.99931H4.99934C4.99934 16.3477 5.20871 16.9207 5.5837 17.3426C5.9587 17.7645 6.46807 18 6.99931 18C7.53055 18 8.03992 17.7645 8.41492 17.3426Z"
              fill="#1F163B"
            />
          </svg>
          <span
            v-if="countUnreadNotifications"
            class="badge"
          >
            <span class="number">
              {{ countUnreadNotifications }}
            </span>
          </span>
        </div>
      </template>
      <template #item="{item}">
        <AppNotificationBellItem
          :item="item"
          @read="onReadNotification"
          @click="onClickNotification"
        />
        <!--        <AppDropdownItem-->
        <!--          :class="{unread: !item.isRead}"-->
        <!--          class="notification"-->
        <!--          @click="onClickNotification(item)"-->
        <!--          @mouseenter="onReadNotification(item)"-->
        <!--        >-->
        <!--          {{ item.title }}-->
        <!--        </AppDropdownItem>-->
      </template>
      <template #empty>
        <AppDropdownItem>
          {{ t('notifications.bell.labels.empty') }}
        </AppDropdownItem>
      </template>
    </AppDropdown>
  </div>
</template>


<style lang="scss" scoped>
.notification-button{
  position: relative;
}
.badge {
  position: absolute;
  width: 17px;
  height: 17px;

  top: -9px;
  left: calc(100% - 7px);
  padding: 4px;
  border-radius: 50%;


  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;

  .number {
    position: relative;
    top: -1px;
  }

  /* red */
  background: #F0494F;

  color: #fff;

  /* white */
  border: 1px solid #FFFFFF;
}

.notifications {
  position: relative;
  padding-top: 5px;
  margin-right: 25px;

  &:hover::before {
    opacity: 1;
  }

  &::before {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgba(45, 45, 134, 0.1);
    opacity: 0;
    transition: opacity .3s;
    z-index: -1;
  }
}
</style>
