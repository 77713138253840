<script lang="ts" setup>
const __MACROS_props = withDefaults(defineProps<MenuProps>(), {
  menuItems: []
})
import { toRefs as __MACROS_toRefs } from "vue";


const {t} = useI18n({
  useScope: "global",
});

interface MenuProps {
  menuItems?: object[];
}

const {menuItems} = __MACROS_toRefs(__MACROS_props);
</script>

<template>
  <nav class="menu">
    <div
      v-for="item in menuItems"
      :key="item.label"
      class="menu__item"
    >
      <NuxtLinkLocale
        v-if="item.to"
        class="menu__item-link"
        :to="item.to"
      >
        {{ item.label }}
      </NuxtLinkLocale>

      <button
        v-else
        class="menu__item-btn"
      >
        <span>{{ item.label }}</span>
        <img
          class="arrow"
          src="/img/icons/arrow-down.svg"
          alt="arrow"
        >
      </button>
      <div
        v-if="item.children"
        class="menu__item-dropdown"
      >
        <NuxtLinkLocale
          v-for="childrenItem in item.children"
          :key="childrenItem?.label"
          :to="childrenItem?.to"
          class="item"
        >
          <span>{{ childrenItem?.label }}</span>
        </NuxtLinkLocale>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.menu {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  max-width: 666px;
  position: relative;
  z-index: 100;

  @media only screen and (max-width: $tablet) {
    display: none;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 222px;
    position: relative;
    z-index: 3;
    transition: background-color 0.3s linear;

    &:has(.router-link-active) {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }

    &:after {
      content: "";
      width: 100%;
      height: 2px;
      background: linear-gradient(58.17deg, #2D2D86 0.79%, #F0494F 100%);
      border-radius: 5px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s, visibility 0.3s;

      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
    }

    &:hover {
      background-color: rgba(45, 45, 134, 0.05);

      &:has(.menu__item-dropdown) {
        &:after {
          opacity: 0;
          visibility: hidden;
        }

      }

      .menu__item-btn {
        .arrow {
          transform: rotate(0);
        }
      }

      .menu__item-dropdown {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }
    }

    .menu__item-link,
    .menu__item-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      height: 100%;
      width: 100%;

      font-size: 14px;
      line-height: 17px;

      color: var(--text-color-1);

      .arrow {
        transition: transform 0.3s;
        transform: rotate(-90deg);
      }
    }

    &-dropdown {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      transition: opacity 0.3s, visibility 0.3s;
      opacity: 0;
      visibility: hidden;
      width: 100%;
      min-width: max-content;
      position: absolute;
      left: 0;
      top: 100%;
      box-shadow: 0px 4px 20px 0px rgba(31, 22, 59, 0.07);
      border-radius: 0 0 8px 8px;
      pointer-events: none;
      overflow: hidden;

      .item {
        padding: 15px 20px;
        transition: background-color 0.3s;
        position: relative;

        font-size: 14px;
        line-height: 17px;

        color: var(--text-color-1);

        &:before {
          content: "";
          height: 100%;
          width: 3px;
          background: linear-gradient(58.17deg, #2d2d86 0.79%, #f0494f 100%);
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.3s, visibility 0.3s;
        }

        &.router-link-active {
          background-color: rgba(45, 45, 134, 0.05);

          &:before {
            opacity: 1;
            visibility: visible;
          }
        }

        &:hover {
          background-color: rgba(45, 45, 134, 0.05);
        }
      }
    }
  }
}
</style>
