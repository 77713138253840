<script lang="ts" setup>
const __MACROS_props = withDefaults(defineProps<LogoProps>(), {
  variant: 'default',
  to: "/"
})
import { toRefs as __MACROS_toRefs } from "vue";
import {FormKitIcon} from '@formkit/vue'

interface LogoProps {
  variant?: "default" | "white";
  path?: string;
  to?: string;
}

const {variant,path,to} = __MACROS_toRefs(__MACROS_props);
const localePath = useLocalePath();

</script>

<template>
  <NuxtLink
    :to="localePath(to)"
    class="logo"
  >
    <FormKitIcon
      v-if="!path"
      :icon="`logo_${variant}`"
    />
    <img
      v-else
      :src="path"
    >
  </NuxtLink>
</template>


<style lang="scss" scoped>
.logo {
  flex-shrink: 0;
    .formkit-icon {
      :deep(svg) {
        @media only screen and (max-width: $tablet) {
          max-height: 30px;
        }
      }
    }
}
</style>
